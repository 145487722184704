var React = require('react');
var ReactDOM = require('react-dom');

import './index.scss';
import App from './App';


ReactDOM.render(
    <App />,
    document.getElementById('app')
);
